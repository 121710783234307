import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tag } from 'src/app/core/model/tag.model';
import { SystemCategory } from 'src/app/core/model/system-category.model';
import { ActivityRange } from 'src/app/core/model/activity-range.model';
import { Attribute } from 'src/app/core/model/attribute.model';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/config';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public getTags(): Promise<Tag[]> {
    return this.get(this.buildApiURL(Config.Api.Tag));
  }

  public getSystemCategories(): Promise<SystemCategory[]> {
    return this.get(this.buildApiURL(Config.Api.Category));
  }

  public getAttributes(): Promise<AttributeStruct> {
    return this.get(this.buildApiURL(Config.Api.Attribute));
  }

  public getActivityRanges(): Promise<ActivityRange[]> {
    return this.get(this.buildApiURL(Config.Api.ActivityRange));
  }

  public async postCondition(tag: Tag): Promise<Tag> {
    const url = `${environment.eportfolio.api}${Config.Api.Tag}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const response: Tag = await this.http.post<Tag>(url, tag, {headers}).pipe(
      catchError(this.handleError<Tag>('Tag request')),
    ).toPromise();

    console.log(response);

    return response;
  }
}

export interface AttributeStruct {
  grades: Attribute[];
  classes: Attribute[];
}
