import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/config';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Activity, SystemCategory, SearchParams, Tag } from 'src/app/shared/model/activity.model';
export class ApiActivityService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    static createAttachmentUrl(activityId, attachmentId) {
        return `${environment.eportfolio.api}${Config.Api.Activity}/${activityId}/attach/${attachmentId}`;
    }
    static keyNameConvert(activity) {
        activity.date_string = activity.dateString;
        return activity;
    }
    getActivities(page = 1, params = {}) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.getWithPaging(this.buildApiURL(Config.Api.Activity), page, params, this.transformActivities);
        });
    }
    getActivity(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Activity, id]), this.transformActivity);
        });
    }
    createActivity(activity) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.post(this.buildApiURL(Config.Api.Activity), activity, this.transformActivity);
        });
    }
    updateActivity(activity) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (activity instanceof FormData) {
                return this.post(this.buildApiURL([Config.Api.Activity, activity.get('id').toString()]), activity, this.transformActivity);
            }
            else {
                return this.post(this.buildApiURL([Config.Api.Activity, activity.id]), activity, this.transformActivity);
            }
        });
    }
    deleteActivity(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.deleteb(this.buildApiURL([Config.Api.Activity, id]));
        });
    }
    deleteAttachment(activityId, attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.deleteb(this.buildApiURL([Config.Api.Activity, activityId, 'attach', attachmentId]));
        });
    }
    getTags() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Tag]));
        });
    }
    getSystemCategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.gets(this.buildApiURL([Config.Api.SystemCategory]));
        });
    }
    likeActivity(activity) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.post(this.buildApiURL([Config.Api.Activity, 'like', activity.id]), activity);
        });
    }
    transformActivity(activity) {
        ApiActivityService.keyNameConvert(activity);
        for (const attachment of activity.attachments) {
            attachment.url = ApiActivityService.createAttachmentUrl(activity.id, attachment.id);
        }
        return activity;
    }
    transformActivities(activityPaginator) {
        for (const activity of activityPaginator.activities) {
            ApiActivityService.keyNameConvert(activity);
            for (const attachment of activity.attachments) {
                attachment.url = ApiActivityService.createAttachmentUrl(activity.id, attachment.id);
            }
        }
        return activityPaginator;
    }
    handleError(operation = 'operation', result = null) {
        return (error) => {
            console.error(error);
            console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);
            if (error.status === 400 && typeof error.error === 'object') {
                throw error.error;
            }
            return of(result);
        };
    }
}
