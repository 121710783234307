/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@angular/material/sidenav/typings/index.ngfactory";
import * as i3 from "@angular/material/sidenav";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/common";
import * as i10 from "./shared/components/navigation/navigation.component.ngfactory";
import * as i11 from "./shared/components/navigation/navigation.component";
import * as i12 from "./core/service/layout.service";
import * as i13 from "./core/service/user.service";
import * as i14 from "./shared/service/storage.service";
import * as i15 from "./core/service/auth.service";
import * as i16 from "./shared/service/like.service";
import * as i17 from "@angular/router";
import * as i18 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { drawer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "mat-drawer-container", [["autosize", ""], ["class", "drawer-container mat-drawer-container"]], [[2, "mat-drawer-container-explicit-backdrop", null]], null, null, i2.View_MatDrawerContainer_0, i2.RenderType_MatDrawerContainer)), i1.ɵdid(2, 1490944, null, 2, i3.MatDrawerContainer, [[2, i4.Directionality], i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef, i5.ViewportRuler, i3.MAT_DRAWER_DEFAULT_AUTOSIZE, [2, i6.ANIMATION_MODULE_TYPE]], { autosize: [0, "autosize"] }, null), i1.ɵqud(603979776, 2, { _drawers: 1 }), i1.ɵqud(603979776, 3, { _content: 0 }), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "mat-drawer", [["class", "drawer-sidenav mat-drawer"], ["mode", "side"], ["tabIndex", "-1"]], [[1, "align", 0], [2, "mat-drawer-end", null], [2, "mat-drawer-over", null], [2, "mat-drawer-push", null], [2, "mat-drawer-side", null], [2, "mat-drawer-opened", null], [40, "@transform", 0]], [["component", "@transform.start"], ["component", "@transform.done"]], function (_v, en, $event) { var ad = true; if (("component:@transform.start" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._animationStartListener($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@transform.done" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6)._animationDoneListener($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatDrawer_0, i2.RenderType_MatDrawer)), i1.ɵdid(6, 3325952, [[2, 4], [1, 4], ["drawer", 4]], 0, i3.MatDrawer, [i1.ElementRef, i7.FocusTrapFactory, i7.FocusMonitor, i8.Platform, i1.NgZone, [2, i9.DOCUMENT]], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-navigation", [], null, null, null, i10.View_NavigationComponent_0, i10.RenderType_NavigationComponent)), i1.ɵdid(8, 114688, null, 0, i11.NavigationComponent, [i12.LayoutService, i13.UserService, i14.StorageService, i15.AuthService, i16.LikeService, i17.Router], null, null), (_l()(), i1.ɵeld(9, 0, null, 2, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(11, 212992, null, 0, i17.RouterOutlet, [i17.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); var currVal_9 = "side"; _ck(_v, 6, 0, currVal_9); _ck(_v, 8, 0); _ck(_v, 11, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._backdropOverride; _ck(_v, 1, 0, currVal_0); var currVal_2 = null; var currVal_3 = (i1.ɵnov(_v, 6).position === "end"); var currVal_4 = (i1.ɵnov(_v, 6).mode === "over"); var currVal_5 = (i1.ɵnov(_v, 6).mode === "push"); var currVal_6 = (i1.ɵnov(_v, 6).mode === "side"); var currVal_7 = i1.ɵnov(_v, 6).opened; var currVal_8 = i1.ɵnov(_v, 6)._animationState; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i18.AppComponent, [i15.AuthService, i12.LayoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i18.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
