import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, OperatorFunction } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/config';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Aspiration, AspirationResult } from 'src/app/shared/model/aspiration.model';
import { ValidationError } from 'src/app/shared/model/validation-error.model';

@Injectable()
export class ApiAspirationService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public async getAspirations(): Promise<AspirationResponse> {
    return this.get(this.buildApiURL([Config.Api.Aspiration]), this.transformAspirations);
  }

  public async getAspirationResults(userId: number = null): Promise<AspirationResultsResponse> {
    if (userId !== null) {
      return this.get(this.buildApiURL([Config.Api.Aspiration, 'result', 'user', userId]), this.transformAspirationResults);
    } else {
      return this.get(this.buildApiURL([Config.Api.Aspiration, 'result']), this.transformAspirationResults);
    }
  }

  public async getAspirationResult(aspirationId: number = null): Promise<AspirationResultResponse> {
    return this.get(this.buildApiURL([Config.Api.Aspiration, aspirationId, 'result']), this.transformAspirationResult);
  }

  public async updateResult(aspirationId: number, aspirationJson: JSON): Promise<Aspiration> {
    return this.post<any>(this.buildApiURL([Config.Api.Aspiration, aspirationId, 'result']),
            aspirationJson, this.transformAspiration);
  }

  public async getCandidates(): Promise<AspirationCandidateResponse> {
    return this.get(this.buildApiURL([Config.Api.Aspiration, 'names']), this.transformAspirationCandidates);
  }

  private transformAspiration(aspiration: Aspiration): Aspiration {
    return aspiration;
  }

  private transformAspirations(aspiration: AspirationResponse): AspirationResponse {
    return aspiration;
  }

  private transformAspirationResults(aspirationResultResponse: AspirationResultsResponse): AspirationResultsResponse {
    return aspirationResultResponse;
  }

  private transformAspirationResult(aspirationResult: AspirationResultResponse): AspirationResultResponse {
    return aspirationResult;
  }

  private transformAspirationCandidates(aspirationCandidate: AspirationCandidateResponse): AspirationCandidateResponse {
    return aspirationCandidate;
  }

 protected handleError<T>(operation = 'operation', result: T = null): OperatorFunction<T, T> {
   return (error: any) => {
     console.error(error);
     console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);

     if (error.status === 400 && typeof error.error === 'object') {
       throw error.error as ValidationError;
     }

     return of(result as T);
   };
 }
}

export interface AspirationResultResponse {
  result: AspirationResult;
  school_aspiration: Aspiration;
}

export interface AspirationResultsResponse {
  results: AspirationResult[];
}

export interface AspirationResponse {
  aspirations: Aspiration[];
}

export interface AspirationCandidateResponse {
  schools: string[];
  departments: string[];
}
