import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/core/model/user.model';
import { Config } from 'src/app/config';

@Pipe({
  name: 'profileImage'
})
export class ProfileImagePipe implements PipeTransform {

  transform(user: User | null): string {
    if (user === null || !Array.isArray(user.attachments) || user.attachments.length === 0) {
      return '/assets/sample_profile.png';
    }
    return `${Config.Api.User}/${user.id}/img`;
  }

}
