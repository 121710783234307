import * as tslib_1 from "tslib";
import { ArrangementType } from 'src/app/app-const';
import { Utility } from 'src/app/shared/class/utility';
import { Config } from 'src/app/config';
import { Activity, Tag, SystemCategory, SearchParams } from 'src/app/shared/model/activity.model';
import { isRoleStudent, User } from 'src/app/core/model/user.model';
export class ActivityService {
    constructor(apiActivityService, storageService) {
        this.apiActivityService = apiActivityService;
        this.storageService = storageService;
    }
    getCurrentArrangementType() {
        const user = this.storageService.getLoginUser().value;
        const storage = Utility.getStorageItem(Config.StorageKey.ArrangementType);
        if (storage === null) {
            const initialArrangementType = { type: ArrangementType.OneColumn };
            Utility.setStorageItem(Config.StorageKey.ArrangementType, initialArrangementType);
            return initialArrangementType.type;
        }
        else if (isRoleStudent(user.roles) && storage.type === ArrangementType.List) {
            return ArrangementType.OneColumn;
        }
        return storage.type;
    }
    updateArrangementType(type) {
        const typeObj = { type };
        Utility.setStorageItem(Config.StorageKey.ArrangementType, typeObj);
    }
    create() {
        return {
            user: {},
            system_category: {},
            attachments: [],
            tags: [],
            like_users: [],
        };
    }
    getActivity(id) {
        return this.apiActivityService.getActivity(id);
    }
    getActivities(page = 1, params = {}) {
        return this.apiActivityService.getActivities(page, params);
    }
    createActivity(activity) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiActivityService.createActivity(activity);
        });
    }
    updateActivity(activity) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiActivityService.updateActivity(activity);
        });
    }
    deleteActivity(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiActivityService.deleteActivity(id);
        });
    }
    deleteAttachment(activityId, attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiActivityService.deleteAttachment(activityId, attachmentId);
        });
    }
    getTags() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiActivityService.getTags();
            // return res ? res : {tags: [], self: []};
        });
    }
    getSystemCategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const res = yield this.apiActivityService.getSystemCategories();
            return res ? res : [];
        });
    }
    likeActivity(activity) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiActivityService.likeActivity(activity);
        });
    }
}
