<div class="container" *ngIf="visible">
  <div class="user">
    <div class="user__main" *ngIf="user">
      <div class="user__main__image">
        <img [attr.src]="user | profileImage | trustUrl | async" alt="profile">
      </div>
      <div class="user__main__info">
        <div class="user__main__info__school">{{ user?.schools[0].name }}</div>
        <div class="user__main__info__name">{{ user | username:'username' }}</div>
        <div class="user__main__info__class">{{ user | username:'short' }}</div>
      </div>
    </div>
    <div class="user__like fav-count">
      <mat-icon>favorite</mat-icon><ng-container *ngIf="like">{{ like?.count }}</ng-container>
    </div>
    <div class="user__follow">
      <a routerLink="/user/follow">
        <div class="user__follow__follow">フォロー
          <span class="user__follow__count" *ngIf="follows">
            {{ follows?.length }}
          </span>
        </div>
      </a>
      <a routerLink="/user/follower">
        <div class="user__follow__follower">フォロワー
          <span class="user__follow__count" *ngIf="followers">{{ followers?.length }}</span>
        </div>
      </a>
    </div>
  </div>
  <div class="menu">
    <ul class="menu__list">
      <ng-container *ngIf="menuList">
        <ng-container *ngFor="let menu of menuList">
          <li class="menu__list__elm"
            *ngIf="isAvailableMenu(menu.name)"
            (click)="clickNavigationItem(menu.item)"
            [ngClass]="{'menu__list__elm--selected': menu.selected}"
          >
            <mat-icon
              [ngClass]="{'menu__list__elm--selected': menu.selected}">
              {{ menu.icon }}
            </mat-icon>
            <div class="menu__list__elm__title"
              [ngClass]="{'menu__list__elm--selected': menu.selected}">
              {{ menu.title }}</div>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>