import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { isRoleAdmin, isRoleRecruit, isRoleTeacher, isRoleStudent } from 'src/app/core/model/user.model';
export class RootComponent {
    constructor(router, storageService, userService) {
        this.router = router;
        this.storageService = storageService;
        this.userService = userService;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.userService.getMe();
            const roles = (user && Array.isArray(user.roles)) ? user.roles : [];
            if (isRoleAdmin(roles)) {
                this.router.navigate(['/school']);
                return;
            }
            if (isRoleRecruit(roles)) {
                this.router.navigate(['/student']);
                return;
            }
            if (isRoleTeacher(roles)) {
                this.router.navigate(['/student/activity']);
                return;
            }
            if (isRoleStudent(roles)) {
                this.router.navigate(['/activity']);
                return;
            }
            this.router.navigate(['/error/unauthorized']);
        });
    }
}
