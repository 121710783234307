import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { isRoleStudent } from 'src/app/core/model/user.model';
export class SearchDialogComponent {
    constructor(dialogRef, searchService, storageService, router, data) {
        this.dialogRef = dialogRef;
        this.searchService = searchService;
        this.storageService = storageService;
        this.router = router;
        this.data = data;
        this.tags = Array();
        this.attributes = { grades: [], classes: [] };
        this.systemCategories = Array();
        this.activityRanges = Array();
        this.user = null;
        this.conditions = {
            range: 1,
            cid: null,
            tid: null,
            user_id: null,
            grade_id: null,
            class_id: null,
            keyword: null
        };
        if (this.router.url === '/student') {
            this.onlyGradeClass = true;
        }
        if (data !== null) {
            this.conditions = data;
        }
        else {
            this.initCondition();
        }
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.onlyGradeClass) {
                this.searchService.getSystemCategories().then((response) => {
                    this.systemCategories = response;
                });
                this.searchService.getTags().then((response) => {
                    this.tags = response;
                });
            }
            if (!this.isStudent()) {
                this.searchService.getAttributes().then((response) => {
                    this.attributes = response;
                });
            }
            else {
                this.searchService.getActivityRanges().then((response) => {
                    this.activityRanges = response;
                });
            }
        });
    }
    itemClicked(itemType, item) {
        if (itemType === 'category') {
            if (this.conditions.cid === item.id) {
                this.initCategoryTag();
            }
            else {
                this.initCategoryTag();
                this.conditions.cid = item.id;
                this.conditions.keyword = '';
            }
        }
        else if (itemType === 'range') {
            this.conditions.range = item.id;
        }
        else if (itemType === 'tag') {
            if (this.conditions.tid === item.id) {
                this.initCategoryTag();
            }
            else {
                this.initCategoryTag();
                this.conditions.tid = item.id;
                this.conditions.keyword = '';
            }
        }
        else if (itemType === 'grade') {
            if (this.conditions.grade_id !== item.id) {
                this.conditions.grade_id = item.id;
            }
            else {
                this.conditions.grade_id = null;
            }
        }
        else if (itemType === 'class') {
            if (this.conditions.class_id !== item.id) {
                this.conditions.class_id = item.id;
            }
            else {
                this.conditions.class_id = null;
            }
        }
    }
    keywordChange() {
        this.initCategoryTag();
    }
    includeItem(itemType, item) {
        if (itemType === 'category') {
            if (this.conditions.cid === item.id) {
                return true;
            }
        }
        else if (itemType === 'range') {
            if (this.conditions.range === item.id) {
                return true;
            }
        }
        else if (itemType === 'tag') {
            if (this.conditions.tid === item.id) {
                return true;
            }
        }
        else if (itemType === 'grade') {
            if (this.conditions.grade_id === item.id) {
                return true;
            }
        }
        else if (itemType === 'class') {
            if (this.conditions.class_id === item.id) {
                return true;
            }
        }
        return false;
    }
    isStudent() {
        const user = this.storageService.getLoginUser().value;
        console.log(user);
        return isRoleStudent(user.roles);
    }
    isShowActivityRange() {
        if (!this.isStudent()) {
            return false;
        }
        const user = this.storageService.getLoginUser().value;
        if (!user || !user.schools || user.schools.length === 0) {
            return false;
        }
        const school = user.schools[0];
        return school.available_menu.some((menu) => {
            return menu === 'view_s_search_range';
        });
    }
    clearCondition() {
        this.initCondition();
        const conditions = this.exclusionNull(this.conditions);
        this.dialogRef.close(conditions);
    }
    exclusionNull(data) {
        if (typeof data !== 'object') {
            return data;
        }
        for (const kstr of Object.keys(data)) {
            const key = kstr;
            data[key] === null ? delete data[key] : this.exclusionNull(data[key]);
        }
        return data;
    }
    onSubmit() {
        const conditions = this.exclusionNull(this.conditions);
        this.dialogRef.close(conditions);
    }
    initCondition() {
        this.initAttribute();
        this.initCategoryTag();
        this.conditions.keyword = '';
    }
    initAttribute() {
        this.conditions.grade_id = null;
        this.conditions.class_id = null;
    }
    initCategoryTag() {
        this.conditions.cid = null;
        this.conditions.tid = null;
    }
}
