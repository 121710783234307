import { Injectable } from '@angular/core';
import { NoticeApiService } from './api/notice-api.service';
import { Readed } from '../model/notice.model';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(
    private noticeApiService: NoticeApiService
  ) { }

  public isAllRead(count: number): boolean {
    if (count === 0) {
      return true;
    }
    return false;
  }

  public getNotices(page: number = 1): Promise<any> {
    return this.noticeApiService.getNotices(page);
  }

  public getUnreadCount(): Promise<any> {
    return this.noticeApiService.getUnreadCount();
  }

  public updateToRead(readed: Readed): Promise<any> {
    return this.noticeApiService.updateToRead(readed);
  }
}
