import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/config';
import { Utility } from 'src/app/shared/class/utility';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/service/storage.service";
import * as i3 from "./user.service";
export class AuthService {
    constructor(http, storageService, userService) {
        this.http = http;
        this.storageService = storageService;
        this.userService = userService;
        this.auth = null;
    }
    signin(username, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${environment.eportfolio.api}${Config.Api.Login}`;
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });
            const body = {
                username,
                password,
            };
            const auth = yield this.http.post(url, body, { headers }).pipe(catchError(this.handleError('Authentication request'))).toPromise();
            if (auth === null) {
                return false;
            }
            Utility.setStorageItem(Config.StorageKey.Auth, auth);
            this.auth = auth;
            const user = yield this.userService.getMe();
            console.log(user);
            if (user === null) {
                return false;
            }
            this.storageService.getLoginUser().onChanged(user);
            return true;
        });
    }
    signout() {
        this.auth = null;
        this.storageService.getLoginUser().onChanged(null);
        Utility.removeStorageItem(Config.StorageKey.Auth);
        // Utility.removeStorageItem(Config.StorageKey.ArrangementType);
        return new Observable((s) => { s.next(true); });
    }
    relatedServiceSignin(relatedServiceAuth) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${environment.eportfolio.api}${Config.Api.RelatedServiceAuth}`;
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });
            const response = yield this.http.post(url, relatedServiceAuth, { headers }).pipe(catchError(this.handleError('Authentication request'))).toPromise();
            if (response === null) {
                return false;
            }
            const auth = { token: response.token };
            Utility.setStorageItem(Config.StorageKey.Auth, auth);
            this.auth = auth;
            const user = yield this.userService.getMe();
            if (user === null) {
                return false;
            }
            this.storageService.getLoginUser().onChanged(user);
            return true;
        });
    }
    get isAuthenticated() {
        if (this.auth !== null) {
            return true;
        }
        const storage = Utility.getStorageItem(Config.StorageKey.Auth);
        if (storage === null) {
            return false;
        }
        this.auth = storage;
        return true;
    }
    get authentication() {
        if (this.isAuthenticated) {
            return this.auth;
        }
        return null;
    }
    handleError(operation = 'operation', result = null) {
        return (error) => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);
            return of(result);
        };
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StorageService), i0.ɵɵinject(i3.UserService)); }, token: AuthService, providedIn: "root" });
