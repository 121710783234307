import { Component, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';
import { GradeGraphMode } from '../../model/confirm-sheet.model';
import { ConfirmSheetService } from '../../service/confirm-sheet.service';


@Component({
  selector: 'app-grade-graph',
  templateUrl: './grade-graph.component.html',
  styleUrls: ['./grade-graph.component.scss']
})
export class GradeGraphComponent implements AfterViewInit {

  @Input() achievements: any;
  @Input() mode: GradeGraphMode;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  graghChart: Chart;
  examNames = [];
  subjects = [];
  results = [];
  graphRankLowest: number;
  graphDeviationMax: number;
  graphDeviationMin: number;

  constructor(
    private confirmSheetService: ConfirmSheetService
  ) { }

  ngAfterViewInit() {
    this.createDataForGraph();
    this.createGraph();
  }

  createDataForGraph(): void {
    this.createThreshold();
    this.examNames = this.createExamNameArray();
    this.subjects = this.createSubjectArray();
    this.results = this.createResultArray();
  }

  createExamNameArray(): any[] {
    const names = [];
    this.achievements.forEach(achivement => {
      const examInfo = achivement.examination;
      names.push({ id: examInfo.id, name: examInfo.name });
    });
    return names;
  }

  createSubjectArray(): any[] {
    const subjects = [];
    this.achievements.forEach(achievement => {
      achievement.details.forEach(detail => {
        if (!subjects.find(s => s.id === detail.curriculum.id)) {
          subjects.push(detail.curriculum);
        }
      });
    });
    subjects.sort((prev, next) => prev.id - next.id);
    return subjects;
  }

  createResultArray(): any[] {
    const results = this.prepareResultsArray();
    this.achievements.forEach(achievement => {
      const examInfo = achievement.examination;
      achievement.details.forEach((detail) => {
        const sameSubjectArray = results.find(result => result.id === detail.curriculum.id);
        sameSubjectArray.grades.forEach(grade => {
          if (grade.exam_id === examInfo.id) {
            grade.score = detail.score;
            grade.rank = detail.rank;
            grade.deviation = detail.deviation;
          }
        });
      });
    });
    console.log(results);
    return results;
  }

  prepareResultsArray(): any[] {
    const results = [];
    this.subjects.forEach(subject => {
      const gradeArray = [];
      this.examNames.forEach(exam => {
        gradeArray.push({
          exam_id: exam.id,
          exam_name: exam.name,
          score: 0,
          rank: this.graphRankLowest,
          deviation: this.graphDeviationMin
        });
      });
      results.push({ id: subject.id, name: subject.name, grades: gradeArray });
    });
    return results;
  }

  createThreshold(): void {
    const ranks = [];
    const deviations = [];
    this.achievements.forEach(achievement => {
      achievement.details.forEach(detail => {
        ranks.push(detail.rank);
        deviations.push(detail.deviation);
      });
    });
    this.graphRankLowest = Math.max(...ranks);
    this.graphDeviationMin = Math.min(...deviations);
    this.graphDeviationMax = Math.max(...deviations);
  }

  createGraph(): void {
    if (this.subjects.length < 1 || this.results.length < 1) {
      return;
    }
    const canvasRef = this.canvas.nativeElement.getContext('2d');
    const subjects = [];
    this.results.forEach((result, index) => {
      const data = [];
      result.grades.forEach(grade => {
        data.push(this.getGraphValue(grade, this.mode));
      });
      subjects.push(this.graphModeDataset(index, data, this.mode));
    });
    const labels = [];
    this.examNames.forEach(exam => {
      labels.push(exam.name);
    });

    this.graghChart = new Chart(canvasRef, {
      type: 'line',
      data: {
        datasets: subjects,
        labels
      },
      options: {
        responsive: true,
        legend: {
          display: true,
          position: 'right',
        },
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: this.graphModeTicks(this.mode),
          }],
        },
      }
    });
  }

  getGraphValue(data, mode): number {
    switch (mode) {
      case GradeGraphMode.Score:
        return data.score;
      case GradeGraphMode.Rank:
        return data.rank === 0 ? this.graphRankLowest : data.rank;
      case GradeGraphMode.Deviation:
        return data.deviation;
      }
    return null;
  }

  graphModeDataset(idx, data, mode): {} {
    const colors = this.confirmSheetService.getColorCodeList(this.subjects.length);
    switch (mode) {
      case GradeGraphMode.Score:
        return {
          data,
          backgroundColor: colors[idx % colors.length],
          label: this.subjects[idx].name,
          reverse: false,
          lineTension: 0,
          fill: false,
          borderColor: colors[idx % colors.length],
        };
      case GradeGraphMode.Rank:
        return {
          data,
          backgroundColor: colors[idx % colors.length],
          label: this.subjects[idx].name,
          reverse: true,
          lineTension: 0,
          fill: false,
          borderColor: colors[idx % colors.length],
        };
      case GradeGraphMode.Deviation:
        return {
          data,
          backgroundColor: colors[idx % colors.length],
          label: this.subjects[idx].name,
          reverse: false,
          lineTension: 0,
          fill: false,
          borderColor: colors[idx % colors.length],
        };
      }
    return {};
  }

  graphModeTicks(mode): {} {
    switch (mode) {
    case GradeGraphMode.Score:
      return {
        min: 0,
        max: 100,
      };
    case GradeGraphMode.Rank:
      return {
        min: 1,
        max: this.graphRankLowest,
        reverse: true,
      };
    case GradeGraphMode.Deviation:
      return {
        min: this.graphDeviationMin,
        max: this.graphDeviationMax,
      };
    }
    return {
      min: 0,
      max: 100
    };
  }

}
