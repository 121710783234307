<div class="dialog-card-container">
	<h3>絞り込み</h3>
	<mat-divider></mat-divider>
	<div class="dialog-card-content">
		<div *ngIf="isShowActivityRange()">
			<h3>参照範囲</h3>
			<mat-divider></mat-divider>
			<mat-list>
				<mat-list-item class="dialog-list-item" *ngFor="let range of activityRanges"
					(click)="itemClicked('range', range)">
					<mat-icon class="done__icon" *ngIf="includeItem('range', range)">done</mat-icon>
					<div>{{ range.name }}</div>
				</mat-list-item>
			</mat-list>
    </div>
		<div *ngIf="!onlyGradeClass">
			<h3>カテゴリ</h3>
			<mat-divider></mat-divider>
			<mat-list>
				<mat-list-item class="dialog-list-item" *ngFor="let category of systemCategories"
					(click)="itemClicked('category', category)">
					<mat-icon class="done__icon" *ngIf="includeItem('category', category)">done</mat-icon>
					<div>{{ category.name }}</div>
				</mat-list-item>
			</mat-list>
		</div>
		<h3>キーワード</h3>
		<mat-divider></mat-divider>
		<mat-form-field class="input-field">
			<input matInput placeholder="キーワードを入力" [(ngModel)]="conditions.keyword" name="keyword" (input)="keywordChange()">
		</mat-form-field>
		<div *ngIf="!isStudent()">
			<h3>学年</h3>
			<mat-divider></mat-divider>
			<mat-list>
				<mat-list-item class="dialog-list-item" *ngFor="let attribute of attributes.grades"
					(click)="itemClicked('grade', attribute)">
					<mat-icon class="done__icon" *ngIf="includeItem('grade', attribute)">done</mat-icon>
					<div>{{ attribute.name }}</div>
				</mat-list-item>
			</mat-list>
			<h3>クラス</h3>
			<mat-divider></mat-divider>
			<mat-list>
				<mat-list-item class="dialog-list-item" *ngFor="let attribute of attributes.classes"
					(click)="itemClicked('class', attribute)">
					<mat-icon class="done__icon" *ngIf="includeItem('class', attribute)">done</mat-icon>
					<div>{{ attribute.name }}</div>
				</mat-list-item>
			</mat-list>
		</div>
		<div *ngIf="!onlyGradeClass">
			<h3>タグ</h3>
			<mat-divider></mat-divider>
			<mat-list>
				<mat-list-item class="dialog-list-item" *ngFor="let tag of tags" (click)="itemClicked('tag', tag)">
					<mat-icon class="done__icon" *ngIf="includeItem('tag', tag)">done</mat-icon>
					<div>{{ tag.name }}</div>
				</mat-list-item>
			</mat-list>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div class="dialog-card-actions">
		<form #searchForm="ngForm" (ngSubmit)="onSubmit()">
			<button mat-raised-button color="primary" type="submit">絞り込む</button>
		</form>
		<button mat-raised-button color="accent" (click)="clearCondition()">条件クリア</button>
	</div>
</div>
