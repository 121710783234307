import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../core/service/auth.service';
import { LayoutService } from '../core/service/layout.service';
import { SettingService } from '../shared/service/setting.service';
import { StorageService } from '../shared/service/storage.service';
import { Utility } from 'src/app/shared/class/utility';
import { Config } from 'src/app/config';

@Component({
  selector: 'app-related-service-auth',
  templateUrl: './related-service-auth.component.html',
  styleUrls: ['./related-service-auth.component.scss']
})
export class RelatedServiceAuthComponent implements OnInit {

  logined: boolean;

  private username: string;
  private accessKey: string;
  private datetime: string;
  private digest: string;
  private service: string;

  constructor(
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private layoutService: LayoutService,
    private settingService: SettingService,
    private storageService: StorageService,
  ) {
    this.logined = false;
  }

  ngOnInit() {
    this.title.setTitle(`学びの記録 ログイン`);
    this.authService.signout();
    this.layoutService.hideNavigation();
    this.layoutService.hideHeader();
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.username = params.get('username');
      this.accessKey = params.get('access_key');
      this.datetime = params.get('datetime');
      this.digest = params.get('digest');
      this.service = params.get('service');
      if (this.username === null || this.accessKey === null
        || this.datetime === null || this.digest === null) {
        this.router.navigate([`/login`]);
      }

      // ローディングを表示してるので、同期しないでおく
      this.authService.relatedServiceSignin({
        username: this.username,
        access_key: this.accessKey,
        datetime: this.datetime,
        digest: this.digest,
      }).then(async (logined) => {
        if (!logined) {
          this.router.navigate([`/login`]);
          return;
        }

        // 遷移元のサービスをローカルストレージに退避
        const serviceType = { service: this.service };
        Utility.setStorageItem(Config.StorageKey.RelatedService, serviceType);

        this.router.navigate([`/`]);
        this.layoutService.showNavigation();
        this.layoutService.showHeader();

        const user = this.storageService.getLoginUser().value;
        this.settingService.updateScoutScopeString(user.setting.scout_scope);
      });
    });
  }

}
