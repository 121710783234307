import { Paginator } from '../model/paginator.model';
import { FormGroup } from '@angular/forms';

export class Utility {

  private constructor() {}

  public static encodeObjectToURIComponent(obj: any): string {
    return Object.keys(obj).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    }).join('&');
  }

  public static getStorageItem(key: string, defaultValue: any = null): any {

    const item = localStorage.getItem(key);
    if (item === null) {
      return defaultValue;
    }

    const obj = JSON.parse(item);
    if (typeof obj !== 'object') {
      return null;
    }

    return obj;
  }

  public static setStorageItem(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public static removeStorageItem(key: string): void {
    localStorage.removeItem(key);
  }

  public static removeAllStorageItem(): void {
    localStorage.clear();
  }

  public static createPaginator(paginator: Partial<Paginator> = {}): Paginator {
    return Object.assign({
      current_page: 0,
      prev_page: null,
      next_page: null,
      max_pages: 0,
      max_per_page: 0,
      total_count: 0,
    } as Paginator, paginator);
  }

  public static convertBase64ToBlob(str: string, type: string): Blob {
    const bytes = atob(str);
    const arr = new Uint8Array(new ArrayBuffer(bytes.length));

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    return new Blob([arr], { type });
  }

  public static isNumString(str: string): boolean {
    return new RegExp(/^[0-9]+$/).test(str);
  }

  public static convertStrToNumber(str: string, defaultValue: number = NaN): number {
    if (!Utility.isNumString(str)) {
      return defaultValue;
    }
    return parseInt(str, 10);
  }

}
