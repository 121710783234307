import * as i0 from "@angular/core";
import * as i1 from "./api/notice-api.service";
export class NoticeService {
    constructor(noticeApiService) {
        this.noticeApiService = noticeApiService;
    }
    isAllRead(count) {
        if (count === 0) {
            return true;
        }
        return false;
    }
    getNotices(page = 1) {
        return this.noticeApiService.getNotices(page);
    }
    getUnreadCount() {
        return this.noticeApiService.getUnreadCount();
    }
    updateToRead(readed) {
        return this.noticeApiService.updateToRead(readed);
    }
}
NoticeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoticeService_Factory() { return new NoticeService(i0.ɵɵinject(i1.NoticeApiService)); }, token: NoticeService, providedIn: "root" });
