import { Injectable } from '@angular/core';
import { ConfirmSheetApiService } from './api/confirm-sheet-api.service';
import { ConfirmSheet, COLOR_CODES, ResumeId } from '../model/confirm-sheet.model';

@Injectable({
  providedIn: 'root'
})
export class ConfirmSheetService {

  constructor(private confirmSheetApiService: ConfirmSheetApiService) { }

  public  getColorCodeList(length: number): string[] {
    return COLOR_CODES.slice(0, length);
  }

  public async getResumes(userId: number): Promise<any> {
    return this.confirmSheetApiService.getResumes(userId);
  }

  public async getAchievement(userId: number, resumeId: number) {
    return this.confirmSheetApiService.getAchievement(userId, resumeId);
  }

  public getSheet(userId: number, resumeId: number): Promise<ConfirmSheet> {
    return this.confirmSheetApiService.getSheet(userId, resumeId);
  }


}
