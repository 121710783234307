export interface ConfirmSheet {
  activities: Activity[];
  aspiration: Aspiration;
  aspiration_result: AspirationResult;
  categories: Category[];
  examinations: Examination[];
  from_at: any;
  resumes: Resume[];
  school: any[];
  to_at: string;
  user: any[];
}

export interface Activity {
  category_id: string;
  id: number;
  like_count: string;
  title: string;
}

export interface Aspiration {
  end_date: string;
  id: number;
  start_date: string;
  title: string;
}

export interface AspirationResult {
  aspiration: any;
  id: number;
}

export interface Category {
  id: number;
  category_count: string;
  name: string;
}

export interface Examination {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  result: any;
}

export interface Resume {
  id: number;
  title: string;
}

export enum ResumeId {
  All = 0,
}

export enum CategorySelector {
  All = '0'
}

export enum GradeGraphMode {
  Score = 0,
  Rank = 1,
  Deviation = 2,
}

export const GRADE_GRAPH_SELECTOR = [
  { name: '得点', value: GradeGraphMode.Score },
  { name: '順位', value: GradeGraphMode.Rank },
  { name: '偏差値', value: GradeGraphMode.Deviation },
];

export const COLOR_CODES = [
  '#3279ef',
  '#2f7ef0',
  '#2d81f1',
  '#2c87f1',
  '#298bf2',
  '#2590f3',
  '#2394f4',
  '#2199f5',
  '#1e9df5',
  '#1ba2f6',
  '#19a6f7',
  '#17abf8',
  '#14b0f9',
  '#13b4f9',
  '#0fb9fa',
  '#0dbdfb',
  '#0ac2fc',
  '#0ac6fd',
  '#0acbfd',
  '#0acffe',
  '#00d4ff',

  // '#3279ef',
  // '#3a7ce4',
  // '#437fd6',
  // '#4b82cc',
  // '#5385c0',
  // '#5c88b4',
  // '#648ba8',
  // '#6c8e9d',
  // '#759191',
  // '#7d9484',
  // '#85977a',
  // '#8e9a6f',
  // '#969d64',
  // '#9ea058',
  // '#a7a34c',
  // '#afa640',
  // '#b8a939',
  // '#c0ac3b',
  // '#c8b03d',
  // '#cfb23e',
  // '#d9b505',

  // '#3279ef',
  // '#00d4ff',
  // '#6932ef',
  // '#c232ef',
  // '#ef32b3',
  // '#ef3237',
  // '#32efb2',
  // '#cdef32',
  // '#efa232',
  // '#b6ef32',
  // '#2ccdac',
  // '#84cd2c',
  // '#cd2c2c',
  // '#cd2cc8',
  // '#485b6f',
  // '#57486f',
  // '#3e2185',
  // '#217385',
  // '#be1c41',
  // '#d9b505',

];
