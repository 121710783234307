import { Injectable } from '@angular/core';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/config';
import { Pagingable } from '../../model/pagingable.model';
import { Notice, Readed } from '../../model/notice.model';

@Injectable({
  providedIn: 'root'
})
export class NoticeApiService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public async getNotices(page: number = 1): Promise<NoticePaginator> {
    return this.getWithPaging<NoticePaginator>(this.buildApiURL(Config.Api.Notice), page);
  }

  public async getUnreadCount(): Promise<any> {
    return this.get(this.buildApiURL([Config.Api.Notice, 'unread', 'count']));
  }

  public async updateToRead(readed: Readed): Promise<any> {
    return this.post<any>(this.buildApiURL([Config.Api.Notice, 'readed']), readed);
  }

}

export interface NoticePaginator extends Pagingable {
  notices: Notice[];
}
