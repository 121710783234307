import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Navigation, StudentMenu, TeacherMenu, AdminMenu, RecruitMenu } from 'src/app/app-const';
import { LayoutService } from 'src/app/core/service/layout.service';
import { UserService } from 'src/app/core/service/user.service';
import { User, Role } from 'src/app/core/model/user.model';
import { School } from 'src/app/core/model/school.model';
import { Like } from 'src/app/shared/model/like.model';
import { StorageService } from '../../service/storage.service';
import { LikeService } from '../../service/like.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { Utility } from 'src/app/shared/class/utility';
import { Config } from 'src/app/config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {

  user: User;
  role: string;
  like: Like;
  follows: User[];
  followers: User[];
  navigation: typeof Navigation = Navigation;
  menuList: any[];

  constructor(
    private layoutService: LayoutService,
    private userService: UserService,
    private storageService: StorageService,
    private authService: AuthService,
    private likeService: LikeService,
    private router: Router
  ) {
    this.cleaningProcess();
  }

  ngOnInit() {
    this.storageService.getLoginUser().observer.subscribe((value) => {
      this.user = value;
      if (this.user) {
        this.initialProcess();
      } else {
        this.cleaningProcess();
      }
    });
    this.storageService.getFollowUsers().observer.subscribe(async (value) => {
      console.log('follow changed');
      this.follows = value;
    });
  }

  initialProcess(): void {
    this.role = this.user.roles[0];
    this.createMenu();
    this.likeService.getLikeCount().then((value) => this.like = value);
    this.userService.getFollows().then((value) => this.follows = value);
    this.userService.getFollowers().then((value) => this.followers = value);
  }

  cleaningProcess(): void {
    this.user = null;
    this.role = '';
    this.like = null;
    this.follows = null;
    this.followers = null;
    this.menuList = [];
  }

  selectCurrentMenu(selectMenu: Navigation): void {
    this.menuList.forEach(menu => {
      if (menu.item === selectMenu) {
        menu.selected = true;
        return;
      }
      menu.selected = false;
      return;
    });
  }

  isAvailableMenu(name: string): boolean {
    if (!this.user) {
      return false;
    }
    if (typeof name !== 'string' || name.length === 0) {
      return true;
    }
    if (!Array.isArray(this.user.schools) || !Array.isArray(this.user.schools[0].available_menu)) {
      return false;
    }

    return this.user.schools[0].available_menu.includes(name);
  }

  get visible(): boolean {
    return this.layoutService.visibleNavigation;
  }

  createMenu() {
    const service = Utility.getStorageItem(Config.StorageKey.RelatedService);
    let menuList = [];

    switch (this.role) {
      case Role.Student:
        menuList = StudentMenu;
        break;
      case Role.Admin:
        menuList = AdminMenu;
        break;
      case Role.Teacher:
        menuList = TeacherMenu;
        break;
      case Role.Recruit:
        menuList = RecruitMenu;
        break;
      default:
        this.router.navigate(['/error/unauthorized']);
    }

    // 「ログイン画面に戻る」「メインメニューへ」の選択
    if (!service || !service.service) {
      menuList = menuList.filter((element, index, array) => {
        return (element.item !== Navigation.ReturnService);
      });
    } else {
      menuList = menuList.filter((element, index, array) => {
        return (element.item !== Navigation.Logout);
      });
    }

    this.menuList = menuList;
  }

  clickNavigationItem(action: Navigation) {
    if (this.layoutService.isSmall()) {
      this.layoutService.toggleHamburger();
    }

    switch (action) {
      case this.navigation.Top:
        this.router.navigate(['/activity']);
        break;
      case this.navigation.New:
        this.router.navigate(['/activity/upsert']);
        break;
      case this.navigation.Grade:
        this.router.navigate(['/grade']);
        break;
      case this.navigation.Aspiration:
        this.router.navigate(['/aspiration']);
        break;
      case this.navigation.Goal:
        this.router.navigate(['/goal']);
        break;
      case this.navigation.ConfirmSeat:
        this.router.navigate(['/confirm_sheet']);
        break;
      case this.navigation.Student:
        this.router.navigate(['/student']);
        break;
      case this.navigation.AllActivities:
        this.router.navigate(['/student/activity']);
        break;
      case this.navigation.Teacher:
        this.router.navigate(['/activity/teacher']);
        break;
      case this.navigation.Setting:
        this.router.navigate(['/setting']);
        break;
      case this.navigation.School:
        this.router.navigate(['/school']);
        break;
      case this.navigation.Logout:
        this.logoutClick();
        break;
      case this.navigation.ReturnService:
        const service = Utility.getStorageItem(Config.StorageKey.RelatedService);
        if (service && service.service === 'cc') {
          const url = environment.cybercampus.url;
          window.location.href = url;
        }
        break;
      default:
        this.router.navigate(['/']);
        break;
    }
    this.selectCurrentMenu(action);
  }

  logoutClick() {
    this.authService.signout().subscribe(state => {
      if (state) {
        this.router.navigate(['/login']);
        this.layoutService.hideNavigation();
        this.layoutService.hideHeader();
      }
    });
  }

}
