import {
  Component, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit
} from '@angular/core';
import { Chart } from 'chart.js';
import { ConfirmSheet } from '../../model/confirm-sheet.model';
import { ConfirmSheetService } from '../../service/confirm-sheet.service';

@Component({
  selector: 'app-activity-graph',
  templateUrl: './activity-graph.component.html',
  styleUrls: ['./activity-graph.component.scss']
})
export class ActivityGraphComponent implements AfterViewInit {

  @Input() data: ConfirmSheet;
  @Output() clickGraphEvent: EventEmitter<number> = new EventEmitter();
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  graghChart: Chart;

  constructor(
    private confirmSheetService: ConfirmSheetService,
  ) { }

  ngAfterViewInit() {
    this.createGraph();
  }

  createGraph(): void {
    const canvasRef = this.canvas.nativeElement.getContext('2d');
    canvasRef.canvas.height = 300;
    const values = [];
    const labels = [];
    this.data.categories.forEach((category) => {
      values.push(category.category_count);
      labels.push(category.name);
    });
    this.graghChart = new Chart(canvasRef, {
      type: 'doughnut',
      data: {
        labels,
        datasets: [{
          data: values,
          backgroundColor: this.confirmSheetService.getColorCodeList(values.length),
        }]
      },
      options: {
        responsive: true,
        legend: {
          position: 'bottom',
          fullWidth: true,
          onClick: () => {}, // prevent filter by default
          labels: {
            generateLabels: (chart: any) => {
              chart.legend.afterFit = function() {
                this.lineWidths = this.lineWidths.map( () => this.width - 12 );
                this.options.labels.padding = 10;
                this.options.labels.boxWidth = 15;
              };
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map((label, i) => {
                  const meta = chart.getDatasetMeta(0);
                  const ds = data.datasets[0];
                  const arc = meta.data[i];
                  const custom = arc && arc.custom || {};
                  const getValueAtIndexOrDefault = (value, index, defaultValue) => {
                    if (value === undefined || value === null) {
                      return defaultValue;
                    }
                    if (Array.isArray(value)) {
                      return index < value.length ? value[index] : defaultValue;
                    }
                    return value;
                  };
                  const arcOpts = chart.options.elements.arc;
                  const fill = custom.backgroundColor ?
                    custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                  const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                  const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                  return {
                    text: label,
                    fillStyle: fill,
                    strokeStyle: stroke,
                    lineWidth: bw,
                    hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                    index: i
                  };
                });
              }
              return [];
            }
          }
        },
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      },
      plugins: [this.chartPluginActivityGraph()]
    });
  }

  chartPluginActivityGraph(): {} {
    return {
      afterDatasetsDraw(chart) {
        let total = '';
        if (chart.data.datasets.length > 0) {
          total = `${chart.getDatasetMeta(0).total} 件`;
        }

        const ctx = chart.chart.ctx;
        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
        const fontSize = 12;

        ctx.font = Chart.helpers.fontString(fontSize, 'normal', 'Arial');
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('活動状況', centerX, centerY - fontSize);
        ctx.fillText('全投稿件数', centerX, centerY + fontSize);
        ctx.fillText(total, centerX, centerY + (fontSize * 2));
      }
    };
  }

  onClickActivityGraph(e): void {
    const elements: any = this.graghChart.getElementAtEvent(e);
    if (elements.length < 1) {
      return;
    }
    const graphIndex = elements[0]._index;
    this.clickGraphEvent.emit(this.data.categories[graphIndex].id);
  }


}
