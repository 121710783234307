import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/layout";
export class LayoutService {
    constructor(breakpointObserver) {
        this.breakpointObserver = breakpointObserver;
        this.BreakPoint = '(max-width: 768px)';
        this.visibleHamburger = true;
    }
    showHeader() {
        this.visibleHeader = true;
    }
    hideHeader() {
        this.visibleHeader = false;
    }
    showNavigation() {
        this.visibleNavigation = true;
    }
    hideNavigation() {
        this.visibleNavigation = false;
    }
    toggleNavigation() {
        this.navigationOpened = !this.navigationOpened;
    }
    toggleHamburger() {
        this.sideMenu.toggle();
        this.updateMasonry();
    }
    showHamburger() {
        this.visibleHamburger = true;
        this.sideMenu.close();
        this.updateMasonry();
    }
    hideHamburger() {
        // this.visibleHamburger = false;
        this.sideMenu.open();
        this.updateMasonry();
    }
    closeNavigation() {
        this.navigationOpened = false;
    }
    breakpointEvent() {
        return this.breakpointObserver.observe([
            this.BreakPoint
        ]);
    }
    isSmall() {
        return this.breakpointObserver.isMatched(this.BreakPoint);
    }
    updateMasonry() {
        if (this.masonry) {
            setTimeout(() => {
                this.masonry.layout();
            }, 500);
        }
    }
}
LayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(i0.ɵɵinject(i1.BreakpointObserver)); }, token: LayoutService, providedIn: "root" });
